.image-upload {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  .close {
    @include close-btn-modal
  }

  .title {
    @include title-modal
  }

  .upload-zone {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 1.5rem;

    .upload-info {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .upload-name {
      padding-left: 1rem;
      padding-top: .5rem;
    }

    .upload-tip,
    .upload-name,
    .upload-link {
      color: $bp-text-color;
    }

    .upload-error {
      color: $bp-red;
    }

    .upload-name {
      span {
        font-weight: bold;
      }
    }

    .upload-link {
      margin-top: 1rem;
      span {
        font-weight: bold;
        border-bottom: 1px solid $bp-text-color;
      }
    }

    .note {
      font-size: small;
    }

  }

  .upload-logo {
    background: url('/images/upload-picture.png');
    text-indent: -9999999px;
    height: 40px;
    width: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .uploaded-image {
    height: 50px;
    width: 50px;
  }

  .btn-upload {
    font-size: 20px;
    margin-bottom: 10px;
    padding: 0 40px;
  }
}
