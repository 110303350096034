.btn {
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  font-family: $page-font;
  min-width: 125px;
  height: 40px;
  line-height: 40px;
  padding: 0 30px;
  outline: none!important;
  box-shadow: none!important;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &.btn-primary {
    background: $bp-blue;
    color: $white!important;
  }

  &.btn-secondary {
    background: $bp-dark-blue;
    color: $white;
  }

  &.btn-tertiary {
    background: $bp-dark-yellow;
    color: $white;
  }

  &.btn-quad {
    background: $bp-text-color;
    color: $white;
  }

  &.btn-red {
    background: red;
    color: $white;
  }

  &.btn-link {
    color: $bp-dark-blue;
    font-size: 13px;

    &:hover {
      text-decoration: none;
    }
  }

  &.btn-outline-primary {
    border: 1px solid $white;
    color: $white;
    padding: 0 15px;
    min-width: unset;
    font-weight: 600;

    &:hover {
      background: $white;
      color: $bp-blue;
    }
  }
}

.minimal-btn {
  background-color: white;
  box-shadow: none !important;
  outline: none !important;
  border: 2px solid;
  border-radius: 4px;
  cursor: pointer;
  padding: 1px 6px;
  font-size: 16px;
  line-height: 20px;

  &:disabled {
    cursor: not-allowed !important;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.minimal-black-btn, a.minimal-black-btn {
  @extend .minimal-btn;
  color: black;
  border-color: black;

  &:hover {
    background-color: black;
    color: white;
  }
}

.minimal-red-btn {
  @extend .minimal-btn;
  color: red;
  border-color: red;

  &:hover {
    background-color: red;
    color: white;
  }
}

.minimal-blue-btn {
  @extend .minimal-btn;
  color: $bp-blue;
  border-color: $bp-blue;

  &:hover {
    background-color: $bp-blue;
    color: white;
  }
}

.minimal-grey-btn {
  @extend .minimal-btn;
  color: #6c757d;
  background-color: white;
  border-color: #6c757d;

  &:hover {
    background-color: #6c757d;
    color: white;
  }
}