
.header {
  height: $header-height;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, .1);
  padding: 20px 0;
  background: $white;
  width: 100%;
}


.m-header {
  @extend .header;
  position: fixed;
  top: 0;
  z-index: 999;

  .container {
    display: flex;
    position: relative;

    .logo {
      height: $logo-height;
    }

    .toggle {
      margin-left: auto;
      cursor: pointer;

      @media(min-width: $tablet-mid) {
        display: none;
      }
    }

    .right-content {
      height: 100vh;
      position: absolute;
      top: $header-height - 20px;
      width: 100%;
      right: -100%;
      transition: right .5s;
      background: $bp-dark-blue;


      &.open {
        right: 0;
      }

      @media(min-width: $tablet-portrait) {
        box-shadow: 0px 3px 40px 0px rgba(0,0,0,.4);
        max-width: 375px;

        &.open {
          right: -25px;
        }
      }

      @media(min-width: $tablet-mid) {
        height: initial;
        position: relative;
        margin-left: auto;
        display: flex;
        background: transparent;
        max-width: unset;
        top: 0;
        right: 0;
        width: initial;
        box-shadow: none;
      }

      nav {
        ul {
          margin: 55px 0;

          @media(min-width: $tablet-mid) {
            height: $logo-height;
            margin: 0;
          }

          li {
            display: block;
            width: 100%;

            @media(min-width: $tablet-mid) {
              display: inline;
              margin: 0 30px;
              line-height: 30px;
              text-align: center;
            }

            a {
              font-family: $page-font;
              font-size: 14px;
              color: $white;
              text-transform: uppercase;
              letter-spacing: 1px;
              font-weight: 400;
              padding: 15px 60px;
              width: 100%;
              display: block;

              &:hover {
                text-decoration: none;
              }

              &.active {
                background: $white;
                color: $bp-blue;
                font-weight: bold;
                box-shadow: 0px 3px 6px 0px rgba(0,0,0,.16);
              }

              @media(min-width: $tablet-mid) {
                font-size: 12px;
                color: $bp-grey;
                box-shadow: none!important;
                background: transparent;
                width: initial;
                display: initial;
                padding: 0;
              }
            }
          }
        }
      }

      .actions {
        padding: 50px 70px;
        border-top: 1px solid #CCD2DF;

        @media(min-width: $tablet-mid) {
          padding: 0;
          border: none;
        }

        .btn-tertiary {
          margin: 0 auto;
          height: 35px;
          line-height: 35px;
          width: 100%;

          @media(min-width: $tablet-mid) {
            margin-top: -2.5px;
            margin-left: 45px;
            width: unset;
          }
        }
      }
    }
  }
}

.a-header {
  @extend .header;
  padding: 15px 30px 15px 15px;
  display: flex;
  justify-content: space-between;

  .logo {
    height: 40px;
    margin-top: 0px;
  }

  .header-components {
    display: none;
    justify-content: flex-end;
    width: 100%;
    padding-right: 30px;

    @media(min-width: $tablet-mid) {
      display: flex;
    }
  }

  .profile-dropdown {

  }
}
