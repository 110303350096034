@mixin info-text-right-bottom($right, $bottom) {
  color: $bp-darkest-blue;
  position: absolute;
  bottom: $bottom;
  right: $right;
  font-style: italic;
  font-size: smaller;

  .help-bubble {
    display: inline;
    margin-left: 5px;
    z-index: 999;

    img {
      width: 14px;
    }


  .text {
    width: 450px;
  }
  }
}

.bimage {
  max-width: 100%;
}

.btitle {
  display: block;
  color: $bp-blue;
  font-size: 38px;
  line-height: 48px;
  font-weight: bold;
  font-family: $lato-font;

  .dot {
    color: #183368;
  }
}

.social-icons {
  text-align: center;

  a {
    margin: 0 8px;
  }
}

.bp-card {
  background: $white;
  border-radius: 6px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  padding: 60px 30px;
  width: 100%;
  color: $bp-text-color;
}

.field-error {
  color: $bp-red;
}

.pricing-feature {
  display: flex;
  border-top: 2px solid $white;
  padding: 15px 0;

  .tick {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 13px;
    }
  }

  .text {
    width: calc(100% - 75px);
    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    color: $bp-dark-blue;

    .btitle {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.8px;
    }
  }
}

.solutions-integrations-widget {
  .tab-links {
    display: flex;
    justify-content: space-around;

    .link {
      font-family: $lato-font;
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 18px;
      border-bottom: 5px solid transparent;
      color: $bp-dark-blue;
      cursor: pointer;
      user-select: none;
      transition: border-bottom-color 0.35s;

      @media (min-width: $tablet-portrait) {
        font-size: 21px;
        line-height: 28px;
      }

      &.active {
        border-bottom-color: $bp-blue;
      }
    }
  }

  .tab-content {
    @extend .bp-card;
    padding: 20px 0;
    overflow: hidden;
    position: relative;

    @media (min-width: $tablet-portrait) {
      padding: 70px 0;
    }

    .content-row {
      width: 200%;
      display: flex;
      position: inherit;
      transition: left 0.35s;

      .tab {
        width: 100%;
        height: 100%;
        display: inline-block;

        .image-row {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          @media (min-width: $tablet-portrait) {
            justify-content: center;
          }

          > div {
            margin: 15px 30px;

            @media (min-width: $tablet-mid) {
              margin: 0 60px;
            }

            img {
              height: 65px;
            }
          }
        }
      }
    }
  }
}

.waiting-list,
.request-callback {
  padding: 60px 0;

  .top {
    text-align: center;

    .logo-square {
      width: 40px;
      margin: 0 0 10px;
    }

    h3 {
      color: $bp-dark-blue;
      margin-bottom: 40px;
    }
  }
}

.waiting-list-form {
  .form-content {
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-portrait) {
      flex-direction: initial;
      justify-content: center;
    }
  }

  .wl-input-group {
    @media (min-width: $tablet-portrait) {
      margin: 0 8px;
    }

    .input-err-message {
      line-height: 50px;
    }
  }

  .form-control {
    border: 2px solid $bp-blue;
    font-size: 14px;
    padding: 0 30px;
    height: 100%;
    height: 50px;

    @media (min-width: $tablet-portrait) {
      width: 250px;
    }
  }

  .btn {
    padding: 0 35px;
    margin: 10px 0 0;
    height: 50px;

    @media (min-width: $tablet-portrait) {
      margin: 0 8px;
    }
  }

  .join-error {
    color: $bp-red;
    padding: 10px 0;
  }

  .join-success {
    color: $bp-green;
  }
}

.callback-form {
  @extend .waiting-list-form;

  .form-control {
    border-color: $bp-dark-blue;
  }
}

.usp-carousel {
  padding: 40px 0;

  @media (min-width: $tablet-mid) {
    padding: 70px 0;
  }

  .usp-slide {
    height: 470px;
    padding: 0 25px;

    @media (min-width: $tablet-portrait) {
      height: 350px;
    }

    @media (min-width: $tablet-mid) {
      //height: 400px;
      display: flex !important;
      padding: 0;
      height: 320px;
    }

    .row {
      align-items: center;

      .usp-image {
        display: block;
        margin: 0 auto;
        max-width: 90%;

        @media (min-width: $mobile-large) {
          max-width: 40%;
        }

        @media (min-width: $tablet-mid) {
          max-width: 70%;
        }

        &.usp-1 {
          max-width: 200px;
          @media (min-width: $tablet-mid) {
            max-width: 300px;
          }
        }

        &.usp-small {
          max-width: 140px;
          @media (min-width: $tablet-mid) {
            max-width: 200px;
          }
        }
      }

      .shopping-bags {
        display: block;
        margin: 0 0 20px;
        @media (min-width: $tablet-mid) {
          width: 70px;
        }
      }

      h3 {
        font-size: 21px;
        line-height: 28px;
        margin: 0 0 20px;
        color: $bp-dark-blue;
        text-align: left;

        @media (min-width: $tablet-mid) {
          max-width: 90%;
          font-size: 28px;
          line-height: 38px;
        }
      }
    }
  }

  //Slicks

  .slick-slide.slick-cloned {
    opacity: 0;
  }

  .slick-dots {
    bottom: -40px;

    @media (min-width: $tablet-mid) {
      bottom: -60px;
    }

    @media (min-width: $desktop) {
      bottom: -80px;
    }

    li {
      margin: 5px;

      @media (min-width: $tablet-portrait) {
        margin: 10px;
      }

      button {
        &:before {
          font-size: 14px;
          color: $bp-text-color;

          @media (min-width: $tablet-portrait) {
            font-size: 22px;
          }
        }
      }

      &.slick-active {
        button:before {
          color: $bp-dark-blue;
        }
      }
    }
  }

  .slick-next {
    right: -5%;
  }

  .slick-prev {
    left: -5%;
  }
}

.slick-next,
.slick-prev {
  color: $bp-dark-blue;
  width: 40px;
  height: 40px;

  &:before {
    font-size: 40px;
    color: $bp-dark-blue;
  }
}

.slick-next {
  right: 15px;
  z-index: 10;
}

.slick-prev {
  left: 15px;
  z-index: 10;
}

.cookie-message {
  position: fixed;
  bottom: 0;
  width: 90%;
  left: 5%;
  border-radius: 10px 10px 0 0;
  padding: 30px 0;
  background: $white;
  z-index: 99999;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

  .row {
    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      &.icon {
        display: none;

        @media (min-width: $tablet-portrait) {
          display: flex;
        }
      }
    }
  }

  .icon {
    font-size: 50px;
  }

  p {
    color: $bp-text-color;

    @media (min-width: $tablet-portrait) {
      margin: 0;
    }

    a {
      color: $bp-green;
      text-decoration: underline;
    }
  }

  .btn {
    background: $bp-green;
    color: $white;
    padding: 0 25px;
  }
}

.doc-links {
  margin-bottom: 60px;

  &.scrolled {
    @media (min-width: $tablet-mid) {
      position: fixed;
      top: ($header-height + 50px);
      max-height: calc(100vh - 250px);
      overflow: scroll;
    }
  }

  a {
    cursor: pointer;
    display: block;
    color: $bp-dark-blue;
    min-height: 40px;
    line-height: 40px;
    margin: 5px 0;
    padding-left: 20px;
    border-left: 3px solid $bp-text-color;

    &.active {
      border-left: 5px solid $bp-blue;
      padding-left: 18px;
    }
  }
}

.faq-snippet {
  border-top: 1px solid #ccd2df;
  border-bottom: 1px solid #ccd2df;
  padding: 130px 0 40px;

  @media (min-width: $tablet-mid) {
    padding: 100px 0;
  }

  h2 {
    color: $bp-dark-blue;
    margin: 0 0 30px;
    font-size: 38px;
    line-height: 48px;
  }
}

.faq-card {
  @extend .bp-card;

  padding: 35px;
  margin-bottom: 20px;
  height: calc(100% - 20px);

  h4 {
    color: $bp-dark-blue;
    margin: 20px 0;
    font-size: 18px;
  }

  p {
    margin: 0;
    font-size: 13px;
    line-height: 26px;
    color: rgba($bp-dark-blue, 0.8);
  }
}

.testimonials {
  text-align: center;
  padding: 50px 0;

  h2 {
    color: $bp-dark-blue;
  }
}

.testimonials-carousel {
  position: relative;
  padding: 40px 0 0;

  @media (min-width: $tablet-portrait) {
    padding: 60px 0 50px;
  }

  @media (min-width: $tablet-mid) {
    padding: 200px 0 50px;
  }

  .slick-list {
    overflow: unset;
    z-index: 5;

    .slick-slide {
      transition: all 1.25s ease-in-out;
      @media (min-width: $tablet-mid) {
        transform: scale(1, 1);
      }

      &.slick-center {
        @media (min-width: $tablet-mid) {
          margin-top: -125px;
          transform: scale(1.2, 1.2);
          z-index: 10;
          position: relative;
        }
      }
    }
  }

  .line {
    position: absolute;
    z-index: 1;
    display: none;

    @media (min-width: $desktop) {
      display: initial;
    }

    &.l7 {
      left: 250px;
      top: 120px;
    }

    &.l8 {
      left: -40px;
      bottom: 0;
    }

    &.l9 {
      right: 130px;
      bottom: 20px;
    }
  }
}

.testimonial-card {
  @extend .bp-card;
  padding: 0;
  margin: 10px;
  width: calc(100% - 20px);

  @media (min-width: $desktop) {
    display: flex;
  }

  > * {
    padding: 45px 35px;
  }

  .details {
    background: rgba($bp-light-grey, 0.15);
    text-align: center;
    padding: 45px 15px 20px;

    @media (min-width: $desktop) {
      flex: 0.5;
      padding: 45px 15px;
    }

    .details-image {
      height: 60px;
      width: 60px;
      border-radius: 50%;
      display: block;
      margin: 0 auto;
    }

    span {
      display: block;
      font-size: 14px;

      &.name {
        color: $bp-dark-blue;
        margin: 20px 0 10px;
        font-family: $lato-font;
        font-weight: bold;
      }

      &.company,
      &.year {
        color: $bp-text-color;
        line-height: 30px;
      }

      &.year {
        font-size: 12px;
      }
    }
  }

  .content {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 30px;

    @media (min-width: $desktop) {
      flex: 0.6;
      padding: 40px 30px;
    }

    p {
      margin: 0;
      font-size: 14px;
    }
  }
}

.list {
  .list-item {
    display: flex;
    margin: 30px 0;

    .tick {
      width: 50px;

      img {
        width: 19px;
      }
    }

    .text {
      width: calc(100% - 75px);
      font-size: 16px;
      line-height: 21px;
      color: $bp-text-color;
      font-family: $lato-font;

      .title {
        font-size: 21px;
        display: block;
        font-weight: bold;
        color: $bp-dark-blue;
        margin-bottom: 2.5px;
      }
    }
  }
}

.join-blueprint {
  @extend .bp-card;
  padding: 40px;
  max-width: 530px;
  margin: 0 auto;

  h2 {
    font-size: 38px;
    line-height: 48px;
    color: $bp-dark-blue;
    text-align: center;
    margin: 0 0 50px;
  }

  .form-container {
    max-width: 340px;
    margin: 0 auto;

    .radio-flex {
      margin: 20px 0;

      .rd {
        display: flex;
        cursor: pointer;

        .pl-logo {
          height: 45px;
        }

        &.woo {
          .pl-logo {
            margin: 0 0 0 10px;
          }
        }
      }
    }
  }

  .btn {
    margin: 20px auto;
    display: block;
  }

  .legals {
    font-family: $lato-font;
    font-size: 12px;
    color: $bp-text-color;
    text-align: center;

    a {
      color: $bp-blue;
      text-decoration: underline;
    }
  }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//App Components

.page-builder {
  display: flex;
  overflow: hidden;
  position: relative;

  .content {
    padding: 25px 40px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - #{$header-height});
  }
}

.sidebar {
  width: 60px;
  height: calc(100vh - #{$header-height});
  transition: width 0.5s;
  border-right: 1px solid rgba(#c1c1c1, 0.4);
  overflow: hidden;
  padding-top: 15px;
  display: flex;
  flex-direction: column;

  &:hover {
    width: 215px;
  }

  .sidebar-link {
    width: 215px;
    display: flex;
    padding: 10px 0;
    text-decoration: none;
    user-select: none;

    &:hover,
    &.active {
      background: rgba($bp-light-grey, 0.2);
    }

    &:last-child {
      border-top: 1px solid #e6e6e6;
      margin: auto 0 220px;
      padding: 15px 0;
    }

    &.disabled {
      pointer-events: none;

      span {
        color: $bp-light-grey;
      }
    }

    img {
      width: 18px;
      display: block;
      margin: 0 22px 0 20px;
    }

    span {
      font-family: $lato-font;
      font-size: 13px;
      font-weight: bold;
      color: $bp-dark-blue;
    }
  }
}

.react-toast-notifications__container {
  top: $header-height !important;
}

.profile {
  max-width: 350px;
  min-width: 250px;

  .details {
    display: flex;
    align-items: center;

    .bp-user {
      width: 32px;
      max-width: 32px;
    }

    .info {
      margin-left: 15px;

      span {
        display: block;

        &.name {
          color: $bp-darkest-blue;
          font-weight: bold;
          font-size: 15px;
          font-family: $lato-font;
        }

        &.email {
          font-size: 12px;
          color: $bp-text-color;
        }
      }
    }
  }
  .profile-dropdown{
    background:white;
    padding: 0px;
    font-size: 13px;
    visibility:hidden;
    z-index: 999999;
    position: relative;
    
    ul {
      padding: 0px;
      border: 1px solid #ddd;
      border-top: 1px solid #fff;  
      position: relative;
      top: 14px;
      margin: 0px;
      background:white;

      li {
        cursor: pointer;
        padding: 8px;
        margin:0px;
        border-top: 1px solid #f5f5f5;

        &:hover{
          border-top: 1px solid #e0f0ff;
          background: #eef5ff;
        }

        img {
          width:20px;
          float:left;
          line-height: 40px;
          display: block;
          margin-top: 8px;
        }
        div {
          margin-left: 28px;
          overflow:hidden;
          text-overflow: ellipsis;
          white-space:nowrap;

          h4 {
            font-size:15px;
            margin: 0px;
          }
          small {
            font-size:12px;
            color: #669;
          }
        }
      }
    }
  }
  &:hover {
    .profile-dropdown{
      visibility:visible;
    }
  }
}

.score-card {
  @extend .bp-card;

  background: $bp-blue;
  padding: 70px 40px 10px;

  .donut-chart {
    position: relative;
    margin: -20px -20px 0;

    svg {
      overflow: visible;
    }
  }

  .logo-container {
    text-align: center;

    .btitle {
      color: $white;
      font-size: 17px;
      letter-spacing: 0.8px;
    }
  }
}

.score-bar {
  position: relative;

  .track {
    position: absolute;
    width: 100%;
    height: 8px;
    background: rgba($bp-dark-blue, 0.5);
    border-radius: 4px;
  }

  .stroke {
    position: absolute;
    width: 0;
    height: 8px;
    background: $white;
    border-radius: 4px;
    transition: width 1s ease-in;
    transition-delay: 5s;
  }
}

.score-bar-group {
  margin: 15px 0;
  padding: 0 0 10px;

  .info {
    color: $white;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;

    .info-title {
      font-weight: bold;
    }
  }
}

.dash-info-card {
  @extend .bp-card;
  padding: 40px 30px;
  margin-bottom: 30px;
  height: calc(100% - 30px);

  .info-title {
    font-family: $lato-font;
    color: $bp-darkest-blue;
    font-size: 18px;
    font-weight: bold;
  }

  .info-value {
    color: $bp-darkest-blue;
    font-size: 38px;
  }

  .info {
    font-size: 12px;
    margin: 15px 0 0;
  }

  .extra-info {
    font-size: 14px;
    margin: 10px 0 -20px;
    color: $bp-darkest-blue;

    .extra-link {
      color: $bp-darkest-blue;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.page-nav {
  margin-bottom: 30px;

  .row {
    > div {
      display: flex;
    }
  }

  .link {
    margin-right: 50px;
    cursor: pointer;

    &.active span {
      color: $bp-blue;
      text-decoration: underline;
    }

    span {
      color: $bp-darkest-blue;
      font-size: 13px;
      font-weight: bold;
      font-family: $lato-font;
    }
  }
}

.demo-phone {
  padding: 30px 15px;
  background: $white;
  border-radius: 20px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  min-height: 550px;

  .ear-piece {
    width: 35px;
    margin: 0 auto;
    background: $bp-light-grey;
    height: 9px;
    border-radius: 4.5px;
  }

  .message-container {
    margin-top: 30px;
    height: 100%;

    .message {
      width: 85%;
      font-family: $lato-font;

      .content {
        background: $bp-dark-blue;
        padding: 15px;
        position: relative;
        border-radius: 10px;
        color: $white;
        font-size: 14px;
        font-weight: bold;
        height: 100%;
        overflow: visible;
        word-break: break-word;
        white-space: pre-wrap;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: -4px;
          background-image: url('https://static.blueprint.store/illustrations/dashboard/bp-demo-sms-tail.svg');
          width: 32px;
          height: 17px;
        }

        .mms-media {
          border-radius: 10px;
          margin-bottom: 10px;
          width: 130px;
        }
      }

      .timestamp {
        font-size: 9px;
        font-style: italic;
        color: $bp-text-color;
        margin: 10px;
      }
    }
  }
}

.bp-toggle {
  height: 20px;
  width: 40px;
  padding: 2px;
  border-radius: 20px;
  background: $bp-text-color;
  cursor: pointer;
  transition: all 0.5s;

  &.active {
    background: $bp-blue;

    .slider {
      margin-left: calc(100% - 16px);
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .slider {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $white;
    transition: all 0.5s;
    margin-left: 0;
  }
}

.cart-message-editor-additional-options{
  margin-bottom: 30px;
  display: flex;
  justify-content: left;
  position: relative;
  top: -40px;

  span {
    margin-left:14px;
  }
}

.cart-message-editor {
  margin-bottom: 25px;
  position: relative;

  &-single {
    @extend .cart-message-editor;
    margin-bottom: 50px;
  }

  .top-bar {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: flex-start;

      .bp-toggle {
        margin-right: 15px;
      }

      .message-number {
        font-weight: bold;
        color: $bp-darkest-blue;
      }

      .help-bubble {
        margin-left: 15px;
      }

      .send-after {
        font-weight: bold;
        color: $bp-darkest-blue;
        margin-right: 15px;
      }
    }
  }

  .message-content {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
    padding: 25px 25px 40px;
    border-radius: 10px;
    border: none;
    resize: none;
    margin: 25px 0;
    width: 100%;
    font-size: 15px;
    color: $bp-darkest-blue;
    font-weight: bold;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .segment-info {
    @include info-text-right-bottom(15px, 40px)
  }

  .message-interval-wrapper {
    margin-top: -5px;
  }
}

.message-editor {
  margin-bottom: 25px;

  &-single {
    @extend .message-editor;
    margin-bottom: 50px;
  }

  .top-bar {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: flex-start;
      margin-bottom: 25px;

      .bp-toggle {
        margin-right: 15px;
      }

      .message-number {
        font-weight: bold;
        color: $bp-darkest-blue;
      }

      .help-bubble {
        margin-left: 15px;
      }

      .send-after {
        font-weight: bold;
        color: $bp-darkest-blue;
        margin-right: 15px;
      }
    }

    .image-preview {
      width: 100%;
      display: block;
      margin: 0px 0px 25px 0px;
      outline: 1px solid lightgray;
      padding: 15px;
    }
  }

  .message-content-container {
    textarea {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
      padding: 20px 25px;
      border-radius: 10px;
      border: none;
      resize: none;
      margin: 5px 0;
      width: 100%;
      font-size: 15px;
      color: $bp-darkest-blue;
      font-weight: bold;
    }
  
    .actions {
      position: relative;
      top: -38px;
      margin-right: 15px;
      display: block;
      float: right;
      background: #fff;
      justify-content: flex-end;
      font-style: italic;
      font-size: smaller;
      line-height: 1.5;
      .segment-info {
        display: inline-flex;
        a {
          margin-left: 4px;
        }
        .segment-count {
          margin-left: 4px;
        }
      }
      .attach-image {
        cursor:pointer;
        margin-left: 6px;
      }
    }
  }
}

.bp-conv-searchbox {
  position: relative;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    left: 20px;
    top: 10px;
    background-image: url('https://static.blueprint.store/illustrations/bp-search-icon.svg');
    position: absolute;
  }

  input {
    height: 40px;
    width: 100%;
    border-radius: 20px;
    border: 1px solid $bp-light-grey;
    padding: 0 0 0 60px;
    line-height: 40px;
  }
}

.help-bubble {
  position: relative;
  display: flex;
  align-items: center;

  &.left {
    .text {
      left: unset;
      right: 25px;
    }
  }

  &.small {
    .text {
      width: 200px;
    }
  }

  .bimage {
    cursor: help;

    &:hover + .text {
      display: initial;
    }
  }

  .text {
    position: absolute;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
    z-index: 999;
    top: -2px;
    left: 25px;
    display: none;
    //margin-left: 5px;
    width: 550px;
    font-size: 14px;
    line-height: 18px;
    color: $bp-darkest-blue;
    background: lighten($bp-light-grey, 8%);
    padding: 2px 5px;
  }
}

.customer-bar {
  width: 270px;
  height: calc(100vh - 70px);
  position: absolute;
  top: 0;
  right: -270px;
  transition: all 0.5s;
  background: $bp-blue;
  padding: 5px 20px;
  z-index: 9;
  overflow-y: auto;

  .note {
    font-size: smaller;
    margin-left: 0.3rem;
    color: white;
  }

  .btn.btn-primary {
    background: $white;
    color: $bp-blue !important;
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  .intro {
    color: $white;
    font-size: 14px;
    font-weight: bold;
  }

  &.active {
    right: 0;
  }

  .customer-info {
    padding: 30px 0;
    border-bottom: 1px solid $bp-light-blue;
    text-align: center;

    .title {
      display: block;
      margin: 0 auto;
      font-weight: bold;
      font-size: 16px;
      color: $white;
    }

    .opted-out {
      color: $white;
    }
  }

  .bot-sect {
    padding: 30px 0;
    border-bottom: 1px solid $bp-light-blue;

    .top {
      display: flex;
      justify-content: space-between;
    }

    .checkout-sms {
      .selected-products {
        color: $white;
        margin-top: 10px;
        text-align: center;
      }
    }
  }

  .unsubscribe-sect {
    padding: 30px 0;
    border-top: 1px solid $bp-light-blue;
  }

  .insights {
    padding: 15px 0 25px;
    border-bottom: 1px solid $bp-light-blue;

    .intro {
      color: $white;
      font-size: 14px;
      font-weight: bold;
    }

    .kpi {
      color: $white;
      font-size: 27px;
      font-weight: bold;
      display: block;

      .small {
        font-size: 18px;
      }
    }

    .kpi-title {
      color: $white;
      font-size: 10px;
      font-weight: bold;
      display: block;
    }
  }

  .customer-accordion {
    .accordion-item {
      border-bottom: 1px solid $bp-light-blue;
      max-height: 50px;
      overflow: hidden;
      transition: all 0.75s;

      &:last-child {
        border-bottom: 0;
      }

      &.active {
        max-height: 250px;

        .bimage {
          transform: rotate(-180deg);
        }

        &.accordion-groups-item {
          max-height: 500px;
        }
      }

      .accordion-header {
        cursor: pointer;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .accordion-title {
          font-size: 14px;
          color: $white;
          font-weight: 900;
        }

        .bimage {
          transition: transform 0.75s;
          margin-right: 10px;
        }
      }

      .accordion-body {
        overflow-y: auto;
        max-height: 200px;

        .info-line {
          color: $white;
          padding: 5px 0;
          font-size: 14px;

          &:first-of-type {
            padding-top: 0;
          }

          .title {
            font-weight: bold;
            display: block;
          }
        }

        .bp-order {
          border: 1px solid $white;
          border-radius: 6px;
          padding: 5px 10px;
          color: $white;
          margin-bottom: 10px;

          .order-number {
            font-size: 14px;
            font-style: italic;
            font-weight: 600;
          }
        }

        .btn-link {
          color: $white;
          height: 17px;
          line-height: 17px;
          width: 100%;
          margin: -10px 0 10px;
        }
      }

      &.accordion-groups-item.active {
        .accordion-body {
          max-height: 500px;
        }
      }
    }
  }
}

.product-grid {
  padding: 10px 0;

  .row {
    justify-content: center;

    > div {
      margin: 0 -10px;
      padding: 0 10px;
    }
  }
}

.product {
  padding: 10px;
  border: 1px solid $bp-light-grey;
  margin: 10px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.5s;
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;

  &:hover {
    box-shadow: 0px 0px 9px 8px rgba(0, 0, 0, 0.15);
  }

  .product-image-container {
    margin: -10px -10px 0;

    .product-image {
      max-width: 100%;
      max-height: 205px;
      margin: 0 auto;
      display: block;
      font-style: italic;
    }
  }

  .product-details {
    padding: 10px 0;

    .product-name {
      display: block;
      color: $bp-dark-blue;
      font-weight: bold;
      font-size: 14px;
      text-transform: capitalize;
    }

    .product-price-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .product-price {
        display: block;
        font-size: 13px;
        font-weight: bold;
        color: $bp-blue;
        margin-top: 5px;
      }

      .product-stock {
        font-size: 11px;
        color: $bp-text-color;

        &.low {
          color: $bp-red;
        }
      }
    }
  }

  .product-buttons {
    margin-top: auto;

    .btn {
      display: block;
      width: 100%;
    }
  }
}

.variant-switcher {
  margin: 10px 0;
}

.editor {
  display: flex;
  flex-direction: row;

  .editor-textarea {
    flex-grow: 1;
    z-index: 1;
    position: relative;

    .toggles {
      position: absolute;
      bottom: 5px;
      right: 20px;
      display: flex;

      .bimage {
        cursor: pointer;
        padding-left: 5px;
      }

      .pickers {
        position: relative;
      }
    }

    .segment-info {
      @include info-text-right-bottom(60px, 4px);

      .help-bubble {
        .text {
          width: 470px;
        }
      }
    }
  }

  .send-button {
    z-index: 0;
    background-color: $bp-blue;
    margin-left: -6px;
    border-radius: 4px;
    border: 1px solid $bp-darkest-blue;
    color: white;
    padding: 0 4px 0 10px;

    &:hover {
      background-color: $bp-dark-blue;
    }
  }

  &.disabled {
    .editor-textarea {
      .toggles .bimage {
        cursor: default;
      }
    }
  }
}

.emoji-picker {
  position: absolute;
  bottom: 30px;
  right: 0;
  max-height: 0;
  transition: max-height 0.5s;
  overflow: hidden;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);

  &.open {
    max-height: 400px;
  }
}

.radio-container {
  margin: 24px 0;
  display: flex;
  cursor: pointer;
  user-select: none;

  .helper-text {
    text-align: left;
    margin-left: 10px;

    &.disabled {
      color: $bp-grey;
      cursor: not-allowed !important;
    }
  }

}
