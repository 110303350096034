.a-view.custom-flows {
  .title {
    display: block;
  }

  .instruction-container {
    width: 80%;
  }

  p {
    color: $bp-text-color;
  }

  .thead-dark th {
    background-color: $bp-dark-blue;
    border-color: $white;
  }

  .centered {
    text-align: center;
  }

  .create-upsell-btn {
    float: right;
    font-size: 14px;
  }

  table {
    margin-top: 20px;
    border: 1px solid $bp-light-grey;
  }

  tr.unavailable {
    background-color: $bp-lightest-grey;
  }

  tr.dragging {
    background-color: $bp-light-blue;
  }

  td {
    color: $bp-text-color;
    vertical-align: middle;

    span {
      cursor: pointer !important;
    }

    .bp-toggle {
      margin-top: 0.3rem;
    }

    .minimal-red-btn {
      margin-left: 0.3rem;
    }
  }

  td.action-btn-container {
    white-space: nowrap;
    padding: 0.75rem 0rem 0.75rem 0rem;

    .minimal-black-btn {
      appearance: button;
      -moz-appearance: button;
      -webkit-appearance: button;
    }
  }

  td.toggle-container {
    text-align: center;
  }

  .drag-drop-icon {
    margin: -4px 10px -2px 0px;
    cursor: grab;
    height: 16px;
  }

  .highlighted-text {
    color: black;
    font-weight: bold;
  }

  .nodeDelete {
    margin-left: -4rem;
    margin-bottom: 2.5rem;
  }
}

.collapsible-text {
  display: inline-block;
}

.selling-type {
  vertical-align: top !important;
}

.custom-tooltip-container  {

  .badge-pill {
    cursor: help !important;

    &:hover + .text {
      display: initial !important;
    }
  }

  &.left {
    .text {
      left: unset;
      right: 25px;
    }
  }

  &.small {
    .text {
      width: 200px;
    }
  }

  .custom-flow-info {
    display: none;
    position: absolute;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
    z-index: 999;
    top: 50px;
    left: 25px;
    display: none;
    font-size: 14px;
    line-height: 18px;
    color: $bp-darkest-blue;
    background: lighten($bp-light-grey, 8%);
    padding: 2px 5px;
  }
}

.help-bubble {
  z-index: 0;
}

.single-custom-flow {
  h2 > a {
    color: $bp-dark-blue;
  }

  .demo-phone {
    min-height: 0;

    .content {
      font-size: smaller !important;
      color: $bp-dark-blue;
    }
  }

  button[type=submit] {
    margin-bottom: 2.4rem;
  }

  button.trash {
    background: none;
    padding: 0px;
    margin: 0px;
    border: none;

    i {
      color: $bp-dark-blue
    }
  }

  label {
    color: $bp-dark-blue;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  textarea.message-content{
    margin-top: 0px;
  }

  .products-container {
    color: $bp-dark-blue;
    padding: 0.375rem 0.75rem;
  }

  .variant-remover {
    cursor: pointer;

    &.disabled {
      color: $bp-light-grey
    }
  }

  .help-bubble {
    display: inherit;
    margin-left: 0.3rem;
    z-index: 2 !important;
  }

  .info {
    font-weight: normal;
    font-size: medium;
  }

  .form-group {
    margin-bottom: 2rem;
  }

  .form-row {
    height: calc(2.25rem + 4px); // Match the max height of Buttons

    button.btn-block {
      padding: initial;
    }

    .filter-actions {
      width: 2.5rem;

      button {
        min-width: auto;
        padding: initial;
        height: auto;
        line-height: initial;
        font-size: large;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
      }
    }
  }

  .message-interval-wrapper {
    flex-direction: row;

    .input-wrapper {
      margin: 0 10px;

      &:first-child {
        margin: 0;
      }
    }
  }

  .bp-toggle-label {
    display: block;
    float: left;
  }

  .bp-toggle {
    float: left;
    margin-left: 15px;
    margin-top: 5px;
  }
}
