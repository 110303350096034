.customer-bar {
  .customer-message-groups {
    padding-bottom: 20px;
    border-bottom: 1px solid #B8E9FD;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        margin-bottom: 10px;

        &:not(:last-child) {
          margin-right: 10px;
        }

        span {
          background-color: $white;
          color: $bp-text-color;
          border-radius: 5px;
          display: inline-block;
          padding: 1px 4px;
          font-size: 12px;
          letter-spacing: 1px;
          -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
          -moz-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
        }
      }
    }

    .no-groups {
      color: $white;
      font-size: 12px;
      margin-bottom: 10px;
    }

    h4 {
      color: $white;
      font-size: 14px;
    }

    select {
      padding-left: 80px;
      color: $bp-blue;
      margin-top: 10px;
    }
  }
}


.a-view.group-messaging {
  .back-btn-container {
    margin-bottom: 20px;

    a {
      color: $bp-darkest-blue;
      text-decoration: underline;

      &:hover {
        color: $bp-blue
      }
    }
  }

  .customer-search-bar {
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
    padding: 30px;

    .checkout-link-section {
      padding: 30px 0;
      border-top: 1px solid $bp-light-blue;
      border-bottom: 1px solid $bp-light-blue;

      .top {
        display: flex;
        flex-direction: row;
        justify-content: center;

        color: $bp-darkest-blue;
        font-size: 15px;
        font-weight: bold;

        .help-bubble {
          display: inline-block;
          margin-left: 5px;
        }
      }

      .checkout-sms {
        text-align: center;

        .btn {
          background: $bp-blue;
          color: white;
          margin-top: 10px;
        }

        .selected-products {
          color: $bp-darkest-blue;
          margin-top: 10px;
          text-align: center;
          font-size: 13px;
        }
      }
    }

    .subscribers-section {
      padding-top: 20px;
      flex-grow: 1;
      height: 100px;
      overflow: hidden;
    }

    h3 {
      margin-bottom: 30px;
    }

    .hide-customer {
      text-decoration: underline;
      margin-bottom: 20px;
      cursor: pointer;
      color: $bp-darkest-blue;
      font-size: 15px;
      font-weight: bold;

      &:hover {
        color: $bp-blue;
      }
    }

    .bp-conv-searchbox {
      margin-bottom: 20px;
    }

    ul {
      overflow-y: auto;
      font-size: 12px;
      color: $bp-darkest-blue;

      li {
        border-bottom: 1px solid;
        padding-right: 0;
        padding-left: 0;

        *:first-child {
          padding-left: 5px;
        }

        *:last-child {
          padding-right: 5px;
        }
      }

      li:first-of-type {
        font-weight: bold;
        color: $bp-darkest-blue;
      }
    }

    .customer-delete {
      cursor: pointer;
      font-weight: bold;

      &:hover {
        color: $bp-blue;
      }
    }

    &.search-conv-card {
      flex-grow: 1;
      height: auto;
      max-height: calc(100vh - 170px);
    }
  }

  .conv-card {
    height: calc(100vh - 170px);

    .info-bar {
      .contact-title {
        display: flex;
        justify-content: space-between;
        margin-right: 0;
      }
    }
  }
}
