@media (max-width: 768px) {
  .sidebar {
    &:hover {
      width: 60px !important;
    }
  }

  .content {
    padding: 25px 0px !important;
  }

  .demo-phone {
    margin-top: 0px !important;
  }
  .customer-bar {
    display: block !important;
    position: static;
    width: 100%;
    margin-top: 10px;
    transition: none;
    height: auto !important;
  }

  .a-header {
    z-index: 9999;
    border-bottom: 1px solid #ddd;
  }

  .messaging {
    margin: -25px -15px 0px -15px !important;
  }

  .conv-card {
    border-radius: 0px !important;
    height: calc(100vh * 0.5) !important;

    .info-bar {
      padding: 8px 16px !important;
      line-height: 1.2em !important;
      overflow: hidden !important;

      .btn {
        margin-top: 4px !important;
      }
    }

    .contact-title {
      font-size: 14px !important;
      display: block !important;
    }
    .contact-sub-title {
      font-size: 12px !important;
      display:block !important;
    }
  }
  .search-conv-card {
    height: calc(100vh * 0.5) !important;
    margin: 0px !important;
    border-radius: 0px !important;

    .intro {
      .bp-conv-searchbox {
        display: none !important;
      }
      .actions {
        display: none !important;
      }
    }

    .all-contacts {
      /*height: auto !important;*/
      height: calc(100%) !important;
    }

    .contact .icon {
      display: none !important;
    }
  }

  .dashboard .col-md-4 {
    margin-bottom: 15px;
  }

  .a-view.analytics .top-bar .dates-container {
    display: none !important;
  }
}
