.create-new-message-group {
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;

  > .title {
    font-size: 30px;
    font-weight: bold;
    color: $bp-dark-blue;
    text-align: center;
    margin-bottom: 20px;
  }

  .btn {
    width: 140px;
  }
}

.create-new-message-group,
.group-messaging {
  .panels {
    display: flex;
    height: 100%;

    .panel {

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      width: 50%;
      padding: 0 15px;
    }
  }

  .subscribers-selected-count {
    text-align: center;
    padding-top: 10px;

    p {
      font-size: 18px;
      font-weight: bold;
      color: $bp-dark-blue;
      border-bottom: 1px solid $bp-dark-blue;
      display: inline-block;
      padding-bottom: 0;
    }
  }

  .group-subscribers {

    display: flex;
    flex-direction: column;
    height: 100%;

    > .title {
      color: $bp-dark-blue;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      line-height: 1.5;
    }

    .group-subscriber-list {
      border-radius: 5px;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
      height: 100%;
      padding: 30px;

      .check-box-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 5px;

        label {
          display: inline-block;
        }
      }

      .styled-check-box {
        border-radius: 5px;
        height: 20px;
        width: 20px;
        border: 1px solid $bp-dark-blue;
        position: absolute;
        display: block;
        top: 2px;
        left: calc(50% - 20px);
        cursor: pointer;

        &::after {
          content: " ";
          position: absolute;
          display: block;
        }

        &.subscribed {
          background-color: $bp-dark-blue;

          &::after {
            left: 6px;
            top: 3px;
            width: 6px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }

      .check-box {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
