.a-view.onboarding {
  h2 {
    color: $bp-dark-blue;
    font-size: 32px;
  }

  .intro {
    margin: 30px 0;
    font-family: $lato-font;
    color: $bp-text-color;
    //font-weight: bold;
    font-size: 18px;
    line-height: 28px;
  }

  .bp-card {
    &.instructions, &.keys {
      padding: 25px 25px 25px 50px;
      margin: 0 0 25px;

      p {
        margin: 0;
        font-size: 13px;
        line-height: 18px;
        font-family: $lato-font;
        color: $bp-dark-blue;
        font-weight: bold;
      }
    }

    &.tutorial {
      padding: 15px;

      img {
       width: 100%;
      }
    }
  }

  .woo-keys-form {
    max-width: 250px;
    position: relative;

    .form-error {
      position: absolute;
      left: 280px;
      bottom: 13px;
      width: 100%;
    }

    .btn {
      display: block;
      width: 100%;
      height: 50px;
      margin-top: 25px;
    }
  }
}
