.contacts {
  form {
    font-size: small;

    .form-control {
      width: auto;
      vertical-align: bottom;
      display: inline;
    }

    .custom-select-wrapper {
      width: auto;

      .custom-select {
        color: white;
        background-color: $bp-blue;
        height: 40px;
      }

      .custom-select:disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }

      .custom-select option {
        background-color: white;
        color: $bp-blue;
        height: 2rem;
        border-bottom: 1px solid black;
      }
    }
  }

  table {
    thead {
      tr {
        th:first-of-type {
          width: 8rem;
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}
