.a-view.up-sells {
  .title {
    display: block;
  }

  .actions {
    margin-top: 25px;
  }

  .instruction-container {
    width: 80%;
  }

  p {
    color: $bp-text-color;
  }

  .thead-dark th {
    background-color: $bp-dark-blue;
  }

  .centered {
    text-align: center;
  }

  .create-upsell-btn {
    float: right;
    font-size: 14px;
  }

  table {
    margin-top: 20px;
    border: 1px solid $bp-light-grey;
  }

  tr.unavailable {
    background-color: $bp-lightest-grey;
  }

  tr.dragging {
    background-color: $bp-light-blue;
  }

  td {
    color: $bp-text-color;
    vertical-align: middle;

    span {
      cursor: pointer !important;
    }

    .bp-toggle {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.3rem;
    }
  }

  td.action-btn-container {
    white-space: nowrap;
    padding: 0.75rem 0rem 0.75rem 0rem;

    .minimal-red-btn {
      margin-left: 0.6rem;
    }
  }

  td.toggle-container {
    text-align: center;
  }

  .drag-drop-icon {
    margin-right: 10px;
    margin-bottom: 4px;
    cursor: grab;
    height: 20px;
  }

  .highlighted-text {
    color: black;
    font-weight: bold;
  }
}

.small {
  .upsell-modal {
    height: 100%;
    a {
      cursor: pointer;
      margin-top: 0.6rem;
      text-decoration: underline;
      text-align: center;
    }

    p {
      color: $bp-dark-blue;
      margin-top: 1rem;
      font-size: 14px;
    }
  }
}

.upsell-modal {
  button[type=submit] {
    font-size: initial;
  }

  label {
    font-weight: bold;
    color: $bp-dark-blue;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  table {
    width: 100%;
    thead:after {
      content: '';
      display: block;
      height: 10px;
    }
    th {
      color: $bp-grey;
      font-weight: 600;
    }
    td {
      @include plain-small-text();
    }

    td.variant-remover {
      cursor: pointer;
    }

    .custom-select-wrapper {
      margin-bottom: 10px;
    }

    .custom-select {
      color: white;
      background-color: $bp-blue;
      height: 2rem;
    }

    .custom-select option{
      background-color: white;
      color: $bp-blue;
      height: 2rem;
      border-bottom: 1px solid black;
    }
  }

  .delete-upsell-btn {
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    width: fit-content;
  }

  .demo-phone {
    min-height: inherit;

    .content {
      font-size: smaller !important;
      color: $bp-dark-blue;
    }
  }

  .form-control {
    font-size: small !important;
  }

  .help-bubble {
    display: inherit;
    margin-left: 0.3rem;
  }

  .message-content {
    margin: 0px;
    color: $bp-dark-blue;
  }

  .message-interval-wrapper {
    max-width: 30%;
    display: inherit;
    margin-left: 0.3rem;

    .icon {
      top: 11px;
    }

    .input-wrapper {
      width: 152px;
    }
  }

  .name-container {
    padding-right: 4rem;

    .send-after-wrapper {
      display: flex;
      align-items: flex-start;
    }

    .message-interval-wrapper {
      margin-top: -5px;
    }
  }

  .note {
    @include plain-small-text()
  }

  .section-head {
    font-size: medium;
  }

  .submit-container {
    margin-top: 1.5rem;
  }

  .upsell-second-row {
    margin-top: 30px;
  }

  .segment-info {
    bottom: 10px;
    right: 10px;
    font-size: small !important;
  }

}
