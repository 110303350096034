.message-groups-table {
  border: 1px solid #ddd;

  thead.thead-dark {
    background-color: $bp-dark-blue;
    color: $white;
    font-weight: bolder;

    th {
      background-color: transparent;
    }
  }

  tbody {
    color: $bp-text-color;

    .actions {
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      * {
        margin-right: 10px;
      }
    }

    .ellipsis {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .clickable {
      position: relative;
      z-index: 2;

      > a {
        color: #8E9CB9;
      }

      &.readOnly a {
        color: #449e20;
      }
    }
  }

  .row {
    margin-right: 0;
    margin-left: 0;
    flex-wrap: nowrap;
  }
}