.message-group-analytics {
  h2 > a {
    color: $bp-dark-blue;
  }

  .totals {
    display: flex;
    flex-direction: row;

    li {
      border: 1px solid $bp-light-grey;
      border-radius: 10px;
      margin: 20px 10px;
      padding: 20px;
      width: 25%;
      text-align: center;
      color: $bp-darkest-blue;

      h4 {
        font-size: 36px;
      }

      h5 {
        font-size: 22px;
        margin-bottom: 20px;
      }

      p {
        color: $bp-text-color;
        margin-bottom: 0;
      }
    }
  }

}
