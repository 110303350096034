.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.70);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
}

.modal {
  position: absolute;
  background: $white;
  overflow: auto;
  border-radius: 6px;
  outline: none;
  padding: 20px;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.video {
    max-width: 750px;
    max-height: 230px;
    padding: 0;
    overflow: hidden;

    @media(min-width: $tablet-portrait) {
      max-height: 420px;
    }
  }

  &.upload {
    max-width: 550px;
    max-height: 590px;
    padding: 0;
    overflow: hidden;
  }

  &.small {
    width: 400px;
    height: 250px;
    padding: 20px;
    overflow: hidden;
  }

  &.wide {
    max-width: 1100px;
    max-height: 750px;
    overflow: auto;
  }

  &.products {
    width: 600px;
    height: fit-content;
    max-height: 600px;
    padding: 1rem 0rem;
    overflow: hidden;

    .product-list-container {
      overflow: auto;
    }
  }

  &.message-group {

    max-width: 850px;

    @media(min-width: $desktop) {
      max-width: 1000px;
    }

    @media(min-width: $desktop-lg) {
      max-width: 1200px;
    }

    max-height: 650px;
    padding: 0;
    overflow: hidden;

    .close {
      position: absolute;
      right: 30px;
      top: 30px;
      text-indent: -9999999px;
      content: " ";
      font-size: 16px;
      width: 20px;
      height: 20px;
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }

      &:before,
      &:after {
        position: absolute;
        left: 10px;
        top: 0;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: $bp-darkest-blue;
      }

      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &.create-message-group {
    max-width: 550px;
    max-height: 350px;
    color: $bp-darkest-blue;

    .btn {
      border: 1px solid;
    }

    h4 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
    }

    .divider {
      height: 1px;
      width: 100%;
    }
  }

  //App modals
  &.logo {
    max-width: 450px;
    max-height: 350px;
    padding: 0;
    overflow: hidden;

    .choose-file {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    .pp-editor {

      .bottom {
        margin-top: 10px;

        .form-error {
          text-align: center;
          width: 100%;
          display: block;
          margin-bottom: -30px;
        }

        .buttons {
          margin: 40px auto 0;
          display: flex;
          justify-content: center;

          .btn {

          }
        }
      }
    }
  }

  &.note {
    max-width: 300px;
    max-height: 200px;
    padding: 20px;
    overflow: hidden;
    text-align: center;

    h4 {
      color: $bp-darkest-blue;
    }

    .form-control {
      resize: none;
      margin-bottom: 20px;
    }

    .buttons {
      display: flex;
      justify-content: center;

      .btn {

      }
    }
  }

  &.paypal {
    max-width: 300px;
    max-height: 250px;
    padding: 20px;
    overflow: hidden;
    text-align: center;

    h4 {
      color: $bp-darkest-blue;
      margin-bottom: 20px;
    }

    .form-control {
      resize: none;
      margin-bottom: 20px;
    }

    .form-error {
      margin-top: -10px;
      margin-bottom: 10px;
      display: block;
    }

    .buttons {
      display: flex;
      justify-content: center;

      .btn {
        min-width: unset;
      }
    }
  }

  &.checkout-link {
    max-width: 500px;
    max-height: 680px;
    padding: 20px;
    overflow: hidden;
    transition: max-height .3s;

    &.link {
      max-height: 340px;
    }

    h4 {
      color: $bp-darkest-blue;
      text-align: center;
    }

    .product-grid {
      height: 420px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .checkout-link-cart {
      padding: 10px 0;
      height: 100px;
      overflow-y: auto;
      overflow-x: hidden;

      .head {
        color: $bp-darkest-blue;
        font-weight: bold;
      }

      .price {
        text-align: right;
      }

      .remove {
        cursor: pointer;
        padding: 0;
      }
    }

    .form-control {
      resize: none;
      //margin-bottom: 20px;
    }

    .form-error {
      margin-top: -10px;
      margin-bottom: 10px;
      display: block;
    }

    .bottom {
      display: flex;
      flex-direction: column;

      .selection {
        display: flex;

        .btn-link {
          padding: 0;
          text-decoration: underline;
        }

        .totals {
          margin: 10px 0 0 auto;
          font-weight: bold;
          color: $bp-dark-blue;

          .value {
            margin-left: 10px;
          }
        }
      }

      .checkout-message {
        textarea {
          resize: none;
          width: 100%;
          margin: 10px 0;
          border: 1px solid $bp-dark-blue;
          border-radius: 6px;
          padding: 5px 10px;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        margin: 10px 0 20px;

        .btn {
          min-width: unset;
        }
      }
    }
  }
}

@mixin close-btn-modal {
  position: absolute;
  right: 2rem;
  top: 1rem;
  visibility: hidden;

  &:after {
    content:'✖';
    font-size: 30px;
    font-weight: 500;
    color: $bp-text-color;
    visibility: visible;
    display: block;
    position: absolute;
    padding: 0;
    top: 0;
    right: 0;
  }
}

@mixin title-modal {
  font-size: 22px;
  font-weight: bold;
  color: $bp-dark-blue;
  text-align: center;
}

@mixin plain-small-text {
  font-weight: normal;
  font-size: smaller;
  margin-left: 0.3rem;
  color: $bp-text-color;
}

.upsell-modal {
  padding: 0rem 2rem 0rem 2rem;
  display: flex;
  flex-direction: column;
  font-size: small;

  .message-interval-select {
    font-size: inherit;
  }

  .close {
    @include close-btn-modal
  }

  .title {
    @include title-modal
  }

  .Overlay {
    background-color: rgba(131, 131, 131, 0.1),
  }
}

.product-selection-modal {
  display: flex;
  flex-direction: column;
  font-size: small;
  max-height: 550px;

  .message-interval-select {
    font-size: inherit;
  }

  .close {
    @include close-btn-modal;
    top: 1.3rem;

    &:after{
      font-size: 20px;
    }
  }

  .title {
    @include title-modal;
    font-size: 18px;
  }

  .list-group-item-action {
    cursor: pointer;
  }
}

.info-modal {
  display: flex;
  flex-direction: column;
  font-size: small;

  .title {
    @include title-modal
  }

  p {
    color: $bp-dark-blue;
    margin-top: 1rem;
    font-size: 14px;
  }

  .Overlay {
    background-color: rgba(131, 131, 131, 0.1),
  }
}
