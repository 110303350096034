.a-view.sms-sentiments {
  .top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .scatter-chart-container {
    height: calc(100vh - 180px);
    width: 100%;
  }

  .custom-tooltip {
    max-width: 400px;
    border: 1px solid black;
    background-color: $bp-lighter-grey;
    padding: 20px;
    border-radius: 4px;
  }

  .message-type-select {
    max-width: 300px;
  }
}
