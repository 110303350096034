.table {
  &.message-list {
    .body-row {
      .total,
      .amount {
        color: $black;
        padding-right: 5px;
      }
    }
  }

  &.customers-list {
    margin-top: 10px;

    .head-wrapper {
      overflow: hidden;
    }

    .head-row {
      color: $bp-dark-blue;
      font-weight: bold;
      font-size: 14px;
      border-bottom: 1px solid $bp-light-grey;
      padding-bottom: 4px;
    }

    .body-wrapper {
      max-height: 397px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .body-row {
      border-bottom: 1px solid $bp-light-grey;
      padding: 1px 0 1px;
      color: $bp-darkest-blue;
      font-weight: bold;
      font-size: 14px;
    }
  }

  &.replies {
    word-break: break-word;
  }
}

.bp-table {
  width: 100%;
  height: 100%;
  overflow: auto;
  table-layout: fixed;

  thead {
    tr {
      th {
        color: $bp-grey;
        font-weight: 600;
        position: sticky;
        top: -1.75rem;
        vertical-align: middle;
      }
    }
  }

  tr > th:last-of-type {
    width: 12rem;
  }

  td {
    @include plain-small-text();
  }

  .noResults {
    text-align: center;
    vertical-align: middle;
  }

  &.bp-table-dark {
    thead {
      tr {
        th {
          background-color: #234e89;
          color: white;
          border: none;
        }
      }
    }
  }
}
