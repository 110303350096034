
.analytics-info-card{
  @extend .bp-card;
  padding: 0px;
  overflow: hidden;

  .info-title{
    padding: 20px 20px 0px 20px;
    font-size: 22px;
  }
  .info-description{
    padding: 0px 20px;
    font-size: 14px;
  
  }
  .info-value {
    padding: 10px 20px 20px 20px;    
    font-size: 22px;
    font-weight: bold;
    color: #183368;
  }

  .info-subvalues{
    border-top: 1px solid #ddd;
    padding: 20px;
    height: 5.5em;
    background: #f5f5f5;
  }
  .info-badsubvalues{
    border-top: 1px solid #ebb;
    padding: 20px;
    background: #fdd;
    color: #966;
  }
}

.analytics-timeline{
  margin-top: 20px;
}
