html,
body {
  overflow-x: hidden;
}

* {
  font-family: $page-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $lato-font;
  font-weight: bold;
}

h1 {
  font-size: 28px;
  line-height: 38px;

  @media (min-width: $tablet-portrait) {
    font-size: 38px;
    line-height: 48px;
  }
}

h3 {
  font-weight: bold;
  color: $bp-darkest-blue;
  font-size: 18px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.m-view {
  padding-top: $header-height;

  .container {
    @media (max-width: $tablet-portrait - 1px) {
      padding-left: 30px;
      padding-right: 30px;

      > .row {
        margin-left: -30px;
        margin-right: -30px;

        [class^='col-'] {
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }
  }
}

.inputFile {
  display: none;
}

label {
  font-size: 13px;
  color: $bp-text-color;
  font-family: $lato-font;
  margin-bottom: -10px;
}

.form-control {
  color: $bp-text-color;
  background: $white;
  border-color: $bp-light-grey;
  border-radius: 4px;
  color: $bp-dark-blue;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    color: $bp-text-color;
    font-size: 14px;
  }
}

.wl-input-group {
  position: relative;
  margin: 10px 0;

  .input-err-message {
    position: absolute;
    color: $bp-red;
    line-height: $input-height;
    font-size: 14px;
    font-style: italic;
    font-family: $lato-font;
    top: 0;
    right: 15px;
  }

  .form-control {
    height: $input-height;
    padding: 12px 15px;
    font-size: 14px;
    font-family: $lato-font;

    &.error {
      border-color: $bp-red;
    }
  }
}

.radio-flex {
  display: flex;
}

.form-error {
  color: $bp-red;
  font-size: 14px;
}

@media (min-width: $desktop-lg) {
  .container {
    max-width: 1300px;
  }
}

/*@media(min-width: $desktop-xl) {
  .container {
    max-width: 1540px;
  }
}*/

.a-view {
  font-family: $lato-font;

  * {
    font-family: $lato-font;
  }

  h2.title {
    font-size: 32px;
    color: $bp-dark-blue;
    margin-bottom: 30px;
  }
}

@mixin icon-style {
  content: '';
  display: block;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 11.6px;
  height: 7.5px;
}

.custom-select-wrapper {
  position: relative;
  cursor: pointer;
  width: 150px;

  .custom-select {
    appearance: none;
    border: none;
    border-radius: 6px;
    background: #ededed;
    padding: 5px 35px 5px 20px;
    color: $black;
    cursor: pointer;
  }

  .icon {
    @include icon-style();
    background-image: url('https://static.blueprint.store/illustrations/bp-select-arrow.svg');
  }

  .icon-white {
    @include icon-style();
    background-image: url('https://static.blueprint.store/illustrations/bp-select-arrow-white.svg');
  }
}

.infotitle {
  cursor: help;
}

a.title-breadcrumb {
  color: $bp-dark-blue;
}

.image-preview {
  background-color: lightgray;
  margin: 10px 10px 0 10px;
  padding: 5px;

  .close-btn {
    float: right;
    cursor: pointer;
    font-size: x-large;
    margin-top: 11px;
  }
}

.copy-icon-message {
  background: #1b1b1b;
  border-radius: 6px;
  color: #fff;
  display: none;
  font-size: .9rem;
  opacity: 1;
  padding: 6px;
  position: absolute;
  z-index: 100;
  animation: fadeInFromNone 0.5s ease-out;
}

@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}
