.a-view.blueprint-value-snapshot {
  .title {
    .btitle {
      display: inline-block;
      font-size: 34px;
    }
  }

  table {
    th:first-of-type {
      width: 33%
    }

    tr > td:first-of-type > div {
      display: flex;
      justify-content: space-between;
    }
  }

}