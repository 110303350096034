.a-view.sms-analytics {
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dates-container {
      display: flex;
      margin-bottom: 20px;
      color: $bp-text-color;
      min-width: 300px;
    }
  }

  .thead-dark th {
    background: $bp-dark-blue;
  }

  th.col-title {
    width: 40%;
  }

  th.col-metric {
    width: 12%;
  }

  .breakdown {
    label {
      font-weight: bold;
      margin-right: 10px;
      color: $bp-dark-blue;
    }
  }

  .pagination {
    color: $black;
    font-size: 13px;
    margin-left: 10px;

    span {
      display: inline-block;

      &.totals {
        margin-right: 20px;
      }

      &.nav {
        cursor: pointer;
        text-decoration: underline;

        &:hover {
          color: $bp-blue;
        }

        &.previous {
          margin-right: 20px;
        }

        &.disabled {
          cursor: default;
          color: $bp-light-grey;
        }

        &.disabled:hover {
          color: $bp-light-grey;
        }
      }
    }

    .totals {
    }
  }
}
