.a-view.dashboard {
  .title {
    .btitle {
      display: inline-block;
      font-size: 34px;
    }
  }

  .dash-card {
    @extend .bp-card;
    padding: 30px;
    height: 100%;

    .btitle {
      margin-bottom: 10px;
    }

    .data {
      span, a {
        display: block;
      }

      a {
        color: $bp-dark-blue;
        text-decoration: underline;
        margin-bottom: 5px;
      }
    }
  }
}
