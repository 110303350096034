.m-footer {
  background: $bp-lightest-grey;
  padding: 55px 35px;

  @media (min-width: $tablet-portrait) {
    padding: 60px 0;
  }

  .logo {
    @media (min-width: $tablet-mid) {
      margin-bottom: 40px;
    }
  }

  .links {
    * {
      color: #234E89;
    }

    .title {
      font-weight: bold;
      margin: 20px 0 10px;
      display: block;
      font-size: 16px;

      @media (min-width: $tablet-mid) {
        margin: 0 0 22px;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 8px 0;

        a {
          font-size: 14px;
        }
      }
    }
  }

  hr {
    border-color: #DDDDDD;
    margin: 30px 0 30px;

    @media(min-width: $mobile-large) {
      margin: 50px 0 30px;
    }

    @media(min-width: $tablet-portrait) {
      margin: 95px 0 30px;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .disclaimer {
      font-weight: bold;
      font-size: 10px;
      color: #DDDDDD;
      margin: 0;
    }

    .social-icons {
      a {
        margin: 0 4px;

        img {
          width: 25px;
        }
      }
    }
  }
}
