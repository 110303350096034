.group-messaging {
  .title-bar {
    display: flex;
    justify-content: space-between;
  }
}

.send-group-message .send-schedule {
  width: 60%;
  margin: 0 auto 0;
  .checkbox {
    margin-top: 16px;
    display: flex;
    width: auto;
    align-items: center;
    input {
      margin-right: 10px;

      &.datetime {
        width: 50%;
      }
    }

    label {
      margin-right: 10px;
      margin: 0 10px 0 0;
      font-weight: bold;
      color: #183368;
    }
  }
  .timezone {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    font-size: 12px;
    text-align: center;
  }
}
