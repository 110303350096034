
.daterangepicker{
  box-shadow: 0px 0px 25px rgba(0,0,0,0.2);

  .drp-buttons .btn{ 
    // fix alignment issue in bootstrap date range select accept button
    padding-top: 0px;
  }
  
}


.date-range-select-input {
  background: url('../../../images/calendar-date.svg');
  background-repeat: no-repeat;
  background-position-x: 8px;
  background-position-y: center;
  padding-left:32px;
  font-size: 90%;
}