.integrations-hub {
  .card {
    background-color: $bp-lightest-grey;
    border-color: $bp-lightest-grey;
    text-align: center;
    align-items: center;

    img {
      width: 80%;
      height: 50px;
    }

    &.active {
      background-color: rgba($bp-green, 0.2);
    }
  }

  .back-btn-container {
    margin-bottom: 20px;

    a {
      color: $bp-darkest-blue;
      text-decoration: underline;

      &:hover {
        color: $bp-blue;
      }
    }
  }

  table {
    .actions {
      button {
        min-width: auto;
        padding: initial;
        height: auto;
        line-height: initial;
        font-size: large;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        &:focus {
          text-decoration: none !important;
        }
      }
    }

    .description {
      padding: 0.575rem 0.75rem;
    }
  }
}
