.subscriber-count-wrapper {
  padding-right: 30px;

  .subscriber-count {
    font-weight: bold;
    color: $bp-darkest-blue;
    display: flex;
  }

  .icon-count {
    height: 32px;
    width: 32px;
    display: block;
    padding-right: 10px;
    box-sizing: content-box;
    padding-top: 4px;
    img {
      height: 32px;
      width: 32px;
    }
  }

  .total-amount {
    padding-top: 8px;
  }
}