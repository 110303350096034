.send-group-message {
  @extend .base-modal;

  max-width: 850px;
  max-height: 630px;
  overflow-y: auto;

  p {
    margin-bottom: 0;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .demo-phone {
    max-height: 400px;
    min-height: 250px;
    margin-bottom: 20px;

    .message-container .message .content {
      font-size: 13px;
      font-weight: normal;
    }
  }

  .help-bubble {
   margin-left: 10px;
  }
}