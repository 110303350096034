.bp-pagination {
  color: $black;
  font-size: 13px;
  margin-left: 10px;
  display: flex;

  *:not(:last-child) {
    margin-right: 20px;
  }

  .totals {
    display: inline-flex;
    align-items: center;
  }

  .nav-link {
    min-width: 0;
    padding: 0;
    color: $bp-darkest-blue;
    text-decoration: none;

    &:hover {
      color: $bp-blue;
      text-decoration: underline;
    }

    &.disabled {
      color: $bp-light-grey;
      text-decoration: none;

      &:hover {
        color: $bp-light-grey;
      }
    }
  }
}