.a-view.messaging, .a-view.group-messaging {
  .search-conv-card {
    @extend .bp-card;
    padding: 20px;
    height: calc(100vh - 120px);
    overflow: hidden;

    .intro {
      > div {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 10px;

        .bimage {
          cursor: pointer;
        }

        .debug {
          @extend .bp-card;
          max-height: 0;
          transition: all 0.5s;
          position: absolute;
          z-index: 10;
          top: 100%;
          padding: 0;
          overflow: hidden;

          &.open {
            max-height: 150px;
            padding: 20px;
          }

          h4 {
            color: $bp-darkest-blue;
          }
        }
      }

      .intro-title {
        font-size: 18px;
        font-weight: bold;
        color: $bp-darkest-blue;
        display: block;
      }
    }

    .actions {
      margin: 20px 0 0;

      .msg-alerts {
        color: $bp-red;
        font-size: 15px;
      }
    }

    .loading {
      margin: 20px -20px -20px;
      padding: 20px;
      border-top: 1px solid $bp-light-grey;
      text-align: center;
    }

    .all-contacts {
      margin: 10px -20px -20px;
      border-top: 1px solid $bp-light-grey;
      overflow: scroll;
      height: calc(100% - 75px);

      .contact {
        border-bottom: 1px solid $bp-light-grey;
        padding: 10px 20px;
        cursor: pointer;

        &.active {
          background: $bp-lightest-grey;
        }

        &.support {
          * {
            color: $bp-red !important;
          }

          .row {
            border-radius: 4px;
            border: 2px solid $bp-red;
            margin: -5px -14px;
            padding: 5px 0;
          }
        }

        .row {
          transition: all 0.3s;
        }

        * {
          transition: color 0.3s;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: -20px;
        }

        .contact-title {
          font-size: 15px;
          font-weight: bold;
          color: $bp-darkest-blue;

          .unread-counter {
            color: $bp-red;
            margin-left: 5px;
            font-size: 12px;
            font-style: italic;
          }
        }

        .contact-info {
          display: flex;
          justify-content: space-between;
          margin-top: 2.5px;

          span {
            font-family: $page-font;
            font-size: 13px;
            font-weight: 600;
            color: $bp-text-color;
          }
        }
      }
    }
  }

  .conv-card {
    @extend .bp-card;
    height: calc(100vh - 120px);
    padding: 0;
    overflow: hidden;

    .info-bar {
      height: 55px;
      background: $bp-text-color;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
      color: $white;
      padding: 0 35px;
      line-height: 55px;
      position: relative;
      z-index: 9;
      display: flex;

      > div {
        display: flex;
        justify-content: space-between;
        flex: 1;

        .btn-outline-primary {
          height: 30px;
          line-height: 30px;

          &:hover {
            color: $bp-text-color;
          }
        }
      }

      .contact-title {
        font-size: 18px;
        font-weight: bold;
        margin-right: 20px;
      }

      .contact-sub-title {
        font-size: 12px;
      }
    }

    .conversation-window {
      height: calc(100% - 185px);
      padding: 20px;
      overflow: scroll;

      .message {
        padding: 0 0 10px;

        &.cus .msg-content {
          background: #ededed;
          color: $bp-darkest-blue;

          &:after {
            content: '';
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: -10px;
            background-image: url('https://static.blueprint.store/illustrations/dashboard/bp-cus-sms-tail.svg');
            width: 17px;
            height: 28px;
          }
        }

        &.agent {
          text-align: right;

          .msg-content {
            background: $bp-text-color;
            margin-left: auto;
            color: $white;

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              right: -8px;
              z-index: -1;
              background-image: url('https://static.blueprint.store/illustrations/dashboard/bp-agent-sms-tail.svg');
              width: 19px;
              height: 28px;
            }
          }
        }

        .msg-content {
          border-radius: 10px;
          max-width: 80%;
          min-height: 37px;
          //min-width: 65px;
          padding: 10px 15px;
          display: inline-block;
          position: relative;
          overflow: visible;
          word-break: normal;
          z-index: 0;
          font-size: 14px;
          line-height: 17px;
          white-space: pre-wrap;
          overflow: hidden;

          &:after {
            background-repeat: no-repeat;
          }

          p {
            margin: 0;
            font-size: 14px;
            text-align: left;

            a {
              text-decoration: underline;
              color: $white;
            }
          }

          img {
            border-radius: 10px;
            margin-bottom: 10px;
            width: 200px;
          }
        }

        .date {
          margin: 5px 0 0;
          font-size: 11px;
          font-style: italic;
          color: $bp-darkest-blue;
        }

        .individual-message-delivery-status {
          margin: -10px 0 0;

          span {
            font-size: 11px;

            &.failed {
              color: $bp-red;
            }

            &.delivered {
              color: $bp-green;
            }

            &.sent {
              color: $bp-yellow;
            }

            &.skipped {
              color: $bp-orange;
            }
          }
        }

        .group-message-delivery-status {
          color: $bp-darkest-blue;
          font-weight: bold;
          font-style: italic;
          font-size: 11px;

          .sent {
            color: $bp-green;
          }

          .failed {
            color: $bp-red;
          }

          .pending {
            color: $bp-yellow;
          }

          .cancelled {
            color: $bp-blue;
          }
        }
      }
    }

    .input-window {
      padding: 20px 10px;

      .form-control {
        resize: none;
        border-color: $bp-darkest-blue;
        padding-bottom: 25px;
      }
      .error {
        color: $bp-red;
        font-size: 12px;
      }
    }
  }
}

.verification-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 14px;
}

.verification-text {
  word-break: break-word;
  font-size: 14px;
}
