.base-modal {
  padding: 40px 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $bp-darkest-blue;

  h3 {
    font-size: 24px;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > *:not(:last-child) {
      margin-bottom: 10px;
    }

    .btn {
      font-size: 16px;
      font-weight: normal;
    }

    .cancel-btn {
      border: 0;
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      color: $bp-darkest-blue;

      &:hover {
        color: $bp-blue;
      }
    }
  }
}
