.edit-segment {
  .predicate-selection {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    padding: 30px;

    .predicate {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      > select {
        width: 20%;
        background-color: $bp-blue;
        color: white;
      }

      & > *:not(:last-child) {
        margin-right: 20px;
      }

      margin-bottom: 10px;
    }

    .trashIcon {
      width: 20px;
      cursor: pointer;
    }

    .subquery {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  label {
    color: $bp-dark-blue;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  button.btn.btn-outline-primary {
    border: 1px solid $bp-blue;
    color: $bp-blue;
    background-color: white;
  }

  .btn-primary {
    margin-top: 30px;
  }

  .subscribers {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      margin-right: 20px;
    }

    div {
      color: $bp-darkest-blue;
    }
  }

  .subscribers .btn, .save .btn {
    width: 186px;
  }
}