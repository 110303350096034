.message-interval-wrapper {
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 10px;
  }

  .input-wrapper {
    position: relative;
    width: 180px;
  }

  &.grey {
    select.message-interval-select, input.message-interval-select {
      appearance: none;
      border: none;
      border-radius: 6px;
      background: #ededed;
      padding: 5px 35px 5px 20px;
      font-size: 16px;
      color: $black;
      cursor: pointer;
      width: 100%;
      height: 2rem;
    }

    .icon {
      content: '';
      display: block;
      position: absolute;
      right: 15px;
      top: 15px;
      width: 11.6px;
      height: 7.5px;
      background-image: url('https://static.blueprint.store/illustrations/bp-select-arrow.svg');
    }

    input.message-interval-select {
      padding-right: 0;
    }
  }

  .days-label {
    position: absolute;
    top: 8px;
    right: -40px;
    color: $bp-darkest-blue;
    font-weight: bolder;
    font-size: 14px;
  }
}