.a-view.settings {
  h2.title {
    margin-bottom: 20px;
  }

  .toggle-container {
    display: flex;
    align-items: center;
  }

  .bp-toggle {
    margin-left: 10px;
  }

  .section-title {
    font-weight: bold;
    color: $bp-darkest-blue;
    text-transform: uppercase;
  }

  .section-title:not(:first-child) {
    margin-top: 30px;
  }

  .pointer {
    cursor: pointer;
  }

  .textSelect {
    user-select: all;
  }

  .settings-card {
    @extend .bp-card;
    padding: 35px 30px;
    margin-bottom: 60px;
    max-width: 600px;
    position: relative;

    &.custom-domains {
      form {
        margin: -10px -15px;
      }
    }

    &.payment-gateways {
      .head-row {
        font-weight: bold;
        margin-bottom: 10px;

        > div {
          border-bottom: 1px solid $bp-darkest-blue;
          padding-bottom: 10px;
          color: $bp-darkest-blue;
        }
      }

      .merch-row {
        padding: 5px 0;
        align-items: center;
        color: $bp-text-color;

        .btn-link {
          padding: 0;
          text-align: left;
        }
      }
    }

    .checkout-settings-container {
      margin-top: 30px;
    }

    .toggle-label {
      vertical-align: super;
    }

    .segment-info-container {
      position: relative;
    }

    .segment-info {
      @include info-text-right-bottom(10px, 20px)
    }

    .fetching-spinner {
      position: absolute;
      top: 1rem;
      right: 1.25rem;
    }
  }

  .settings-form {
    .small {
      max-width: 35%;
    }

    .buttons {
      width: 100%;
      display: flex;
      position: absolute;
      top: calc(100% + 20px);
      left: 0;
    }
  }

  .brand-details-form {
    .disabled,
    select:disabled {
      opacity: 0.4;
      color: $bp-grey;
    }

    select {
      &.away-option {
        &.offline {
          color: $bp-grey;
        }
      }
    }

    .stripe {
      .btn {
        padding: 0;
      }
    }

    .away-hours {
      .weekday {
        margin: 15px 0 10px 0;

        label {
          font-weight: bold;
          font-size: 13px;
          color: $bp-dark-blue;
          margin-bottom: 20px;
        }
      }

      label {
        &.time {
          margin: 3px 0;
        }
      }

      .field-error.time {
        margin: -10px 0 20px 0;
      }
    }

    .message-content {
      margin: 0;
    }

    @media (min-width: 576px) {
      .away-hours {
        .weekday {
          margin: 0;

          label {
            margin: 0;
            padding: 0;
            min-width: 70px;
            font-size: 11px;
            display: block;
            display: inline-block;
          }
        }

        select {
          font-size: 13px;
          padding-left: 3px;
          padding-right: 3px;
        }

        label {
          &.time {
            margin-right: 1px;

            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      .away-hours {
        .weekday {
          margin: 0;

          label {
            font-size: 13px;
            min-width: 100px;
            display: inline-block;
          }
        }

        select {
          font-size: 16px;
        }

        label {
          &.time {
            margin-right: 10px;

            &:not(:first-child) {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

.a-view.settings .settings-card .subscription-settings {
  .toggle {
    display: flex;
    margin-bottom: 20px;

    span {
      margin-left: 10px;
      font-weight: bold;
      color: #183368;
    }
  }

  .input {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
    
    label {
      margin-left: 20px;
    }

    input {
      width: 30%;
      margin-left: 10px;
      margin-right: 10px;
    }

    span,
    label {
      line-height: 1;
    }
  }
}