.form {
  width: 400px;
}

.btn-container {
  display: flex;
  margin-top: 24px;
}

.sr-combo-inputs {
  margin: 20px 0;
}

.sr-combo-inputs-row {
  width: 100%;
  display: flex;
  padding-bottom: 12px;
  position: relative;
}

.sub-btn {
  background: #3ab8e8;
  border-radius: 6px;
  color: #fff;
  border: 0;
  padding: 12px 16px;
  margin-right: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}

.sub-btn.success {
  background: #009a44;
}

.sr-input {
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 5px 12px;
  height: 44px;
  width: 100%;
  transition: box-shadow 0.2s ease;
  background: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #32325d;
}

.sr-input::placeholder {
  color: #aab7c4;
}

.link {
  color: $bp-blue;
}

.sr-field-error {
  position: absolute;
  top: 62px;
  color: crimson;
  text-align: left;
  font-size: 16px;
  line-height: 17px;
  margin-top: -10px;
  margin-bottom: 20px;
  font-style: italic;
}

.sr-card-element {
  padding-top: 12px;
}

button:disabled {
  opacity: 0.5;
  cursor: none;
}
