@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Lato:400,400i,700,900|Open+Sans:400,400i,600,700');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
@import 'variables';
@import 'global';
@import 'components';
@import 'buttons';
@import 'header';
@import 'footer';
@import 'modals';
@import 'tables';
@import 'mobile';

@import '_utils/stretched-link.scss';

@import '_pages/dashboard';
@import '_pages/onboarding';
@import '_pages/analytics';
@import '_pages/messaging';
@import '_pages/settings';
@import '_pages/abandoned-carts';
@import '_pages/login';
@import '_pages/group-messaging';
@import '_pages/sms-analytics';
@import '_pages/group-segment';
@import '_pages/up-sells.scss';
@import '_pages/cross-sells.scss';
@import '_pages/sms-sentiments';
@import '_pages/contacts.scss';
@import '_pages/integrations-hub.scss';
@import '_pages/custom-flows';
@import '_pages/smart-links.scss';
@import '_pages/blueprint-value-snapshot.scss';

@import '_components/image-upload';
@import '_components/message-groups';
@import '_components/message-group-analytics';
@import '_components/customer-message-groups';
@import '_components/payment-form';
@import '_components/segment-count-down';
@import '_components/subscriber-count';
@import '_components/edit-segment';
@import '_components/group-details';
@import '_components/message-groups-table';
@import '_components/modal';
@import '_components/remove-customer-from-message-group-modal';
@import '_components/send-group-message-modal';
@import '_components/unsubscribe-btn';
@import '_components/note-modal';
@import '_components/note-section';
@import '_components/pagination';
@import '_components/message-interval-select';
@import '_components/date-range-select';
