.m-view.login {
  .login-card {
    @extend .bp-card;

    padding: 40px;
    max-width: 530px;
    margin: 30px auto;
    text-align: center;

    h2 {
      font-size: 38px;
      line-height: 48px;
      color: $bp-dark-blue;
      text-align: center;
      margin: 0 0 50px;
    }

    .form-container {
      max-width: 340px;
      margin: 0 auto;
    }

    .btn {
      margin: 20px auto;
      display: block;
    }

    .privacy {
      margin: 20px auto;
      font-size: 15px;
    }

    .privacy a {
      color: $bp-blue;
    }
  }
}
