.add-note {
  @extend .base-modal;

  max-width: 400px;
  max-height: 300px;

  .date {
    font-size: 12px;
    font-style: italic;
  }

  p {
    font-size: 14px;
    margin-left: 15px;
  }
}